import React, { memo, useMemo } from 'react';
import { format } from 'date-fns';
import Formatters from 'domain/formatters';

// Components
import HtmlParser from 'presentation/components/molecules/HtmlParser/HtmlParser';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import CardCategory from '../CardCategory';
import CardInfo from '../CardInfo';

import utilData from '../../utils/data/data';
import { costoAdministracionString } from '../../utils/costoDeAdministracion';

// Def
import { CategoryProps } from './Category.def';

// Styled
import Styled from './Category.style';

const COUNTRY = process.env.REACT_APP_COUNTRY?.toLocaleLowerCase();

const Category = ({
  showCongrat,
  data,
  dataCategory,
  dataNextCategory,
  currentPoints = 0,
}: CategoryProps) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const JULY = 6;
  const isMobile = useIsMobile();
  const accuCat = dataCategory.accumulationCategory;

  const accumulationName =
    dataCategory?.accumulationCategory.name &&
    dataCategory?.accumulationCategory.name.toLowerCase();

  const categoryName =
    dataCategory?.currentCategory.name &&
    dataCategory?.currentCategory.name.toLowerCase();
  const nextCategoryName =
    dataNextCategory?.name && dataNextCategory?.name.toLowerCase();

  const progressPoints = useMemo(
    () => (currentPoints * 100) / dataNextCategory?.requiredPoints,
    [currentPoints, dataNextCategory?.requiredPoints],
  );

  const dataCurrentAccumulationCategory = useMemo(
    () => utilData.infoCategorias.find(item => item.name === accumulationName),
    [accumulationName],
  );

  const dataCurrentCategory = useMemo(
    () => utilData.infoCategorias.find(item => item.name === categoryName),
    [categoryName],
  );

  const dataInfoNextCategory = useMemo(
    () => utilData.infoCategorias.find(item => item.name === nextCategoryName),
    [nextCategoryName],
  );

  // Tarjeta normal, platimium y elite
  const productCandidateToDiscount = ['00', '01', '02'];
  const isCandidateToDiscount = productCandidateToDiscount.includes(
    dataCategory.productCategory.id,
  );

  // Categoria Fan y Premium
  const accumulationToGiveDiscount = ['04', '01'];
  const isAcumulationCandidate = accumulationToGiveDiscount.includes(
    accuCat.id,
  );

  const frontTo: React.ReactNode = accuCat.startDate && accuCat.endDate && (
    <>
      {Formatters.date(accuCat.startDate.toString())} hasta el{' 31-'}
      {format(new Date(accuCat.endDate), 'MM-yyyy')}
    </>
  );

  return (
    <Styled.Container>
      <Styled.WrapperContent>
        <Styled.AccumulationCardsContainer isMobile={isMobile}>
          {/* Felicidades Card */}
          {showCongrat && (
            <CardCategory
              progressBar={false}
              icon={categoryName}
              title="¡Felicidades!">
              {data?.congratMessage && (
                <HtmlParser text={data?.congratMessage} />
              )}
              {/* La siguiente comprobación asegura que la fecha "desde hasta" se muestre siempre y 
              cuando el congrat sea por acumulacion y no por producto y que este dentro de las fechas */}
              {data?.congratBy === 'acumulacion' &&
                accuCat.endAlertBenefitDate &&
                new Date(accuCat.endAlertBenefitDate) > new Date() &&
                frontTo}
            </CardCategory>
          )}
          {/* Fin Felicidades Card */}

          {/* Subir de Categoria Card */}
          {dataNextCategory.name &&
            dataNextCategory.requiredPointsToUpgrade > 0 && (
              <CardCategory
                progressBar
                icon={nextCategoryName}
                title="Te faltan"
                points={Formatters.points(
                  dataNextCategory?.requiredPointsToUpgrade,
                )}
                progressPoints={Math.abs(progressPoints)}
                color={dataInfoNextCategory?.color}>
                Para subir a categoría{' '}
                <span style={{ color: `${dataInfoNextCategory?.color}` }}>
                  {dataInfoNextCategory?.name}
                </span>{' '}
                por acumulación hasta el 31-12-{currentYear}
              </CardCategory>
            )}
          {/* Fin Subir de Categoria Card */}

          {/* Puntos para mantener categoria */}
          {currentMonth >= JULY && accuCat.requiredPointsToMaintain > 0 && (
            <CardCategory
              progressBar
              icon={accumulationName}
              title="Consigue"
              points={Formatters.points(accuCat.requiredPointsToMaintain)}
              progressPoints={Math.abs(progressPoints)}
              color={dataCurrentAccumulationCategory?.color}>
              Antes del 31-12-{currentYear} para mantener tu categoría{' '}
              <span
                style={{
                  color: `${dataCurrentAccumulationCategory?.color}`,
                }}>
                {dataCurrentAccumulationCategory?.name}
              </span>{' '}
              hasta el{' 31-'}
              {accuCat.endDate && format(new Date(accuCat.endDate), 'MM-yyyy')}
            </CardCategory>
          )}
          {/* Fin Puntos para mantener categoria */}

          {/* Puntos para obtener descuento */}
          {dataNextCategory.requiredPointsToUpgrade > 0 &&
            isCandidateToDiscount &&
            isAcumulationCandidate &&
            COUNTRY !== 'co' && (
              <CardCategory
                progressBar
                icon={categoryName}
                title="Te faltan"
                points={Formatters.points(
                  dataNextCategory.requiredPointsToUpgrade,
                )}
                progressPoints={Math.abs(progressPoints)}
                color={dataCurrentCategory?.color}>
                Para obtener{' '}
                <b>
                  {accuCat.id === '01' ? '100% ' : '50% '}
                  de dcto.
                </b>{' '}
                en {costoAdministracionString} de tu tarjeta CMR
              </CardCategory>
            )}
          {/* Fin Puntos para mantener categoria */}
        </Styled.AccumulationCardsContainer>

        {/* Info Card */}
        <CardInfo
          data={dataCategory}
          showCongrat={showCongrat}
          frontTo={frontTo}
        />
        {/* Fin Info Card */}
      </Styled.WrapperContent>
    </Styled.Container>
  );
};

export default memo(Category);
